import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=75851b7a&scoped=true&"
import script from "./Settings.js?vue&type=script&lang=js&"
export * from "./Settings.js?vue&type=script&lang=js&"
import style0 from "./Settings.vue?vue&type=style&index=0&id=75851b7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75851b7a",
  null
  
)

export default component.exports